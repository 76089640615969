<template>
  <div v-if="loaded">
    <PublicFinishedPoolResults v-if="poolData.status == 'COMPLETED'" />
    <PublicEventLivePool v-else-if="poolData.status == 'OPEN'" />
    <div class="placeholder-message" v-else>
      <h4>
        {{ poolData.round.pools.length > 1 ? "Pool" : "Round" }} not played yet
      </h4>
      <p>Once a round is finished the results will automagically be updated.</p>
    </div>
  </div>
</template>

<script>
import PublicFinishedPoolResults from "@/pages/PublicFinishedPoolResults";
import PublicEventLivePool from "@/pages/PublicEventLivePool";
export default {
  name: "PublicEventPoolResults",
  components: {
    PublicFinishedPoolResults,
    PublicEventLivePool,
  },
  data() {
    return {
      poolData: null,
      loaded: false,
    };
  },
  watch: {
    $route: function () {
      this.loadData();
    },
  },
  computed: {},
  methods: {
    async loadData() {
      let poolData = await this.$axios({
        data: {
          query: `
          {
            pool(poolId:"${this.$router.currentRoute.params.poolId}"){
              id
              status
              round{
                pools{
                  id
                }
              }
            }
          }
          `,
        },
      });

      this.poolData = poolData.data.data.pool;
      this.loaded = true;
    },
  },
  beforeMount() {
    this.loadData();
  },
  beforeCreate() {},
  destroyed() {},
};
</script>

<style lang="scss" scoped>
.placeholder-message {
  margin-top: 50px;
  text-align: center;
  h4 {
    width: 100%;
    font-size: 20px;
    margin-bottom: 16px;
  }
  p {
    font-size: 16px;
    color: $strom;
  }
}
@media (min-width: 480px) and (orientation: landscape) {
}

@media (min-width: 544px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
  .placeholder-message {
    text-align: center;
    h4 {
      font-size: 24px;
      margin-bottom: 24px;
    }
    p {
      font-size: 18px;
      color: $strom;
    }
  }
}
</style>
