<template>
  <div class="pool-results" v-if="!loading">
    <template v-if="poolData.status == 'CLOSED'">
      <div class="message">
        <div class="header">
          <span>This round is due to start on</span>
        </div>
        <div class="body">{{ poolDate(poolData.date) }}</div>
      </div>
    </template>

    <template
      v-if="poolData.status == 'COMPLETED' && poolData.leaderboard.length > 0"
    >
      <div
        class="division-container"
        v-for="division in poolLeaderboards"
        v-if="division.players.length > 0"
        :class="{ watched: division.type == 'WATCHED' }"
        :key="division.type"
      >
        <div class="player-info" :class="resultsTableSize">
          <div class="banner-left">
            <p>
              <b>{{ division.name }}</b> ({{ division.players.length }})
            </p>
          </div>
          <div class="player-info-columns">
            <div class="pos-column" @click="setSorting('place')">
              <p>
                <b
                  >POS<span
                    class="active-sort"
                    v-if="utility.sortingCriteria == 'place'"
                  ></span
                ></b>
              </p>
            </div>
            <div class="player-column" @click="setSorting('firstName')">
              <p>
                <b
                  >PLAYER<span
                    class="active-sort"
                    v-if="utility.sortingCriteria == 'firstName'"
                  ></span
                ></b>
              </p>
            </div>
            <div class="total-column">
              <p>
                <b>THW</b>
              </p>
            </div>
            <div class="round-column">
              <p>
                <b>PAR</b>
              </p>
              <p class="pool-par">{{ poolPar }}</p>
            </div>
          </div>
          <div class="players">
            <div
              class="player"
              :class="{
                'no-border': shownStatRows.includes(player.playerId),
              }"
              v-for="player in division.players"
              :key="division.type + player.playerId"
            >
              <div class="position">
                <p>{{ player.place }}</p>
              </div>
              <div class="name" @click="toggleStatRows(player.playerId)">
                <p>
                  <b>{{ player.firstName }} {{ player.lastName }}</b>
                </p>
                <Chevron2
                  :direction="
                    shownStatRows.includes(player.playerId) ? 'up' : 'down'
                  "
                />
              </div>
              <div class="total">
                <p>
                  {{ player.score }}
                </p>
              </div>
              <div class="round">
                <p>
                  <b>
                    {{ parFormatter(player.roundPar) }}
                  </b>
                </p>
              </div>
              <div
                class="player-stats-info-portrait"
                v-if="shownStatRows.includes(player.playerId)"
              >
                <div class="main-stats">
                  <div class="circle-hits portrait-stat">
                    <p>
                      <b>Circle hits</b> ({{ player.stats.circleHits.count }})
                    </p>
                    <p>
                      <b>{{ toPercentage(player.stats.circleHits.part) }}</b
                      >%
                    </p>
                    <div class="bar">
                      <span
                        class="overlay"
                        :style="{
                          width:
                            toPercentage(player.stats.circleHits.part) + '%',
                        }"
                      ></span>
                    </div>
                  </div>
                  <div class="conversions portrait-stat">
                    <p>
                      <b>Conversions</b> ({{
                        player.stats.circleHitConversions.count || 0
                      }})
                    </p>
                    <p>
                      <b>{{
                        toPercentage(player.stats.circleHitConversions.part)
                      }}</b
                      >%
                    </p>
                    <div class="bar">
                      <span
                        class="overlay"
                        :style="{
                          width:
                            toPercentage(
                              player.stats.circleHitConversions.part
                            ) + '%',
                        }"
                      ></span>
                    </div>
                  </div>
                  <div class="inside-putts portrait-stat">
                    <p>
                      <b>Inside putts</b> ({{ player.stats.insidePutts.count }})
                    </p>
                    <p>
                      <b>{{ toPercentage(player.stats.insidePutts.part) }}</b
                      >%
                    </p>
                    <div class="bar">
                      <span
                        class="overlay"
                        :style="{
                          width:
                            toPercentage(player.stats.insidePutts.part) + '%',
                        }"
                      ></span>
                    </div>
                  </div>
                  <div class="op-ob portrait-stat">
                    <p><b>Outside putts</b></p>
                    <div class="outside-putts">
                      <p>
                        <b>{{ player.stats.outsidePutts.count }}</b>
                      </p>
                    </div>
                    <p class="ob-text">
                      <b>OB</b>
                    </p>
                    <div
                      class="out-of-bounds"
                      :class="{ red: player.stats.outOfBounds.count > 0 }"
                    >
                      <p>
                        <b>{{ player.stats.outOfBounds.count }}</b>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="player-stats-info"
                v-if="shownStatRows.includes(player.playerId)"
              >
                <div class="left"></div>
                <div class="stat-titles">
                  <div class="circle-hits">
                    <p>Circle hits:</p>
                  </div>
                  <div class="conversions">
                    <p>Conversions:</p>
                  </div>
                  <div class="inside-putts">
                    <p>Inside putts:</p>
                  </div>
                  <div class="outside-putts">
                    <p>Outside putts:</p>
                  </div>
                  <div class="out-of-bounds">
                    <p>Out of Bounds:</p>
                  </div>
                </div>
                <div class="total-percentage">
                  <div>
                    <p v-if="roundNumber == 1">
                      <b>{{ toPercentage(player.stats.circleHits.part) }}%</b>
                    </p>
                    <p v-else>
                      {{ toPercentage(player.stats.circleHits.part) }}%
                    </p>
                  </div>
                  <div>
                    <p v-if="roundNumber == 1">
                      <b
                        >{{
                          toPercentage(player.stats.circleHitConversions.part)
                        }}%</b
                      >
                    </p>
                    <p v-else>
                      {{
                        toPercentage(player.stats.circleHitConversions.part)
                      }}%
                    </p>
                  </div>
                  <div>
                    <p v-if="roundNumber == 1">
                      <b>{{ toPercentage(player.stats.insidePutts.part) }}%</b>
                    </p>
                    <p v-else>
                      {{ toPercentage(player.stats.insidePutts.part) }}%
                    </p>
                  </div>
                  <div>
                    <p v-if="roundNumber == 1">
                      <b>{{ player.stats.outsidePutts.count }}</b>
                    </p>
                    <p v-else>
                      {{ player.stats.outsidePutts.count }}
                    </p>
                  </div>
                  <div>
                    <p v-if="roundNumber == 1">
                      <b>{{ player.stats.outOfBounds.count }}</b>
                    </p>
                    <p v-else>
                      {{ player.stats.outOfBounds.count }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="player-results" :class="resultsTableSize">
          <div class="banner-right"></div>
          <div class="result-table-wrapper">
            <div class="player-results-columns">
              <div
                class="hole-info"
                v-for="hole in poolData.layoutVersion.holes"
              >
                <p class="hole-number">
                  <b>{{ hole.number }}</b>
                </p>
                <p class="hole-par">{{ hole.par }}</p>
              </div>
            </div>
            <div class="players">
              <div
                class="player"
                v-for="player in division.players"
                :key="division.type + player.playerId"
              >
                <div class="results">
                  <div
                    v-if="result"
                    class="result"
                    :class="[parCalc(result.playerPar, result.score)]"
                    v-for="result in player.playerResults"
                  >
                    <p>
                      <b v-if="result.score">{{ result.score }}</b>
                    </p>
                  </div>
                  <div class="result empty" v-else></div>
                </div>
                <div
                  class="player-stats"
                  v-if="shownStatRows.includes(player.playerId)"
                >
                  <div class="circle-hits">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isCircleHit"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                  <div class="conversions">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isConversion"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                  <div class="inside-putts">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isInsidePutt"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                  <div class="outside-putts">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isOutsidePutt"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                  <div class="out-of-bounds">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isOutOfBounds"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import { orderBy } from "lodash";
import { convertRGBA } from "@/mixins/ConvertRGBA";
import moment from "moment";
import Chevron2 from "@/assets/icons/Chevron2";

export default {
  name: "PublicFinishedPoolResults.vue",
  mixins: [convertRGBA],
  components: { Chevron2 },
  data() {
    return {
      testexpanded: false,
      showByIndex: null,
      shownStatRows: [],
      animatedStatRows: [],
      poolData: {},
      loading: true,
      tableLoaderSequence: 0,
      utility: {
        showPars: true,
        animationDelay: true,
        useListAnimation: false,
        sortingCriteria: "place",
        sortDirection: "asc",
      },
    };
  },
  methods: {
    setSorting(criteria) {
      if (this.utility.sortingCriteria == criteria) {
        this.utility.sortDirection == "asc"
          ? (this.utility.sortDirection = "desc")
          : (this.utility.sortDirection = "asc");
      }
      this.utility.sortingCriteria = criteria;
    },
    toggleStatRows(id) {
      this.utility.useListAnimation = false;
      if (this.shownStatRows.includes(id)) {
        this.shownStatRows = this.shownStatRows.filter(
          (playerId) => id != playerId
        );
      } else {
        this.shownStatRows.push(id);
      }
    },
    holeDisplayName(hole) {
      if (hole.name != null) {
        return hole.name;
      } else {
        return hole.number;
      }
    },
    handler() {
      this.loading = true;

      this.$nextTick(() => {
        this.loading = false;
      });
    },
    playerHoleStats(results) {
      let aces = 0;
      let stats = {
        circleHits: {
          count: 0,
          part: 0,
        },
        circleHitConversions: {
          count: 0,
          part: 0,
          possibleAttempts: 0,
        },
        insidePutts: {
          count: 0,
          part: 0,
        },
        outsidePutts: {
          count: 0,
          part: 0,
        },
        outOfBounds: {
          count: 0,
          part: 0,
        },
      };
      results.forEach((result) => {
        if (result.score == 1) {
          aces++;
        }
        if (result.isCircleHit) {
          stats.circleHits.count++;
          stats.circleHitConversions.possibleAttempts++;
        }
        if (result.isInsidePutt && result.isCircleHit) {
          stats.circleHitConversions.count++;
        }
        if (result.isInsidePutt) {
          stats.insidePutts.count++;
        }
        if (result.isOutsidePutt) {
          stats.outsidePutts.count++;
        }
        if (result.isOutOfBounds) {
          stats.outOfBounds.count++;
        }
      });

      stats.circleHits.part = stats.circleHits.count / results.length;
      stats.insidePutts.part =
        stats.insidePutts.count /
        (results.length - stats.outsidePutts.count - aces);

      if (stats.circleHits.count > 0) {
        stats.circleHitConversions.part =
          stats.circleHitConversions.count /
          (stats.circleHitConversions.possibleAttempts - aces);
      }

      return stats;
    },
    toggleStatRow(playerId) {
      if (this.shownStatRows.includes("stats" + playerId)) {
        this.animatedStatRows = this.animatedStatRows.filter(
          (player) => player !== "stats" + playerId
        );
        setTimeout(() => {
          this.shownStatRows = this.shownStatRows.filter(
            (player) => player !== "stats" + playerId
          );
        }, 300);
      } else {
        this.shownStatRows.push("stats" + playerId);
        this.$nextTick(() => {
          this.animatedStatRows.push("stats" + playerId);
        });
      }
    },
    toPercentage(part) {
      return (part * 100).toFixed(0);
    },
    checkStat(hole, player, stat) {
      let show = false;

      player.results.forEach((result) => {
        if (result.hole.id == hole.id && result[stat]) {
          show = true;
        }
      });
      return show;
    },
    playerPoolPar(results) {
      let poolResult = 0;

      for (var i = 0; i < results.length; i++) {
        let holePar = this.poolData.layoutVersion.holes.filter(
          (hole) => hole.id == results[i].hole.id
        )[0].par;
        let playerHoleScore = results[i].score;
        let holeResult = playerHoleScore - holePar;

        poolResult += holeResult;
      }

      return this.parFormatter(poolResult);
    },
    parFormatter(par) {
      if (par == 0 || par == null) {
        return "E";
      } else if (par > 0) {
        return "+" + par;
      } else {
        return par;
      }
    },
    playerTotalFormatter(result) {
      if (result == null) {
        return 0;
      } else {
        return result;
      }
    },
    resultWidth() {
      if (this.poolData.layoutVersion.holes.length > 27) {
        return "xs";
      } else if (this.poolData.layoutVersion.holes.length > 18) {
        return "s";
      } else if (this.poolData.layoutVersion.holes.length > 9) {
        return "m";
      } else if (this.poolData.layoutVersion.holes.length > 3) {
        return "l";
      }
    },
    isPlayerDNF(results) {
      if (results.length != this.poolData.layoutVersion.holes.length) {
        return true;
      } else {
        false;
      }
    },
    calcStatValues(player) {
      let below = 0;
      let par = 0;
      let above = 0;

      let circleHit = 0;
      let conversion = 0;
      let insidePutt = 0;
      let outsidePutt = 0;

      for (var i = 0; i < player.results.length; i++) {
        if (player.results[i].isCircleHit) circleHit++;
        if (player.results[i].isCircleHit && player.results[i].isInsidePutt)
          conversion++;
        if (player.results[i].isInsidePutt) insidePutt++;
        if (player.results[i].isOutsidePutt) outsidePutt++;

        if (player.results[i].score - player.results[i].hole.par < 0) below++;
        if (player.results[i].score - player.results[i].hole.par == 0) par++;
        if (player.results[i].score - player.results[i].hole.par > 0) above++;
      }

      function percentage(value, base) {
        if (base != 0) {
          let newvalue = Math.round((value / base) * 100);
          return [newvalue, 100 - newvalue];
        } else {
          return [0, 100];
        }
      }

      let stats = {
        below: {
          part: percentage(below, player.results.length),
          count: below,
        },
        par: {
          part: percentage(par, player.results.length),
          count: par,
        },
        above: {
          part: percentage(above, player.results.length),
          count: above,
        },
        circlehit: {
          part: percentage(circleHit, player.results.length),
          count: circleHit,
        },
        conversion: {
          part: percentage(conversion, circleHit),
          count: conversion,
        },
        insideputt: {
          part: percentage(insidePutt, player.results.length - outsidePutt),
          count: insidePutt,
        },
        outsideputt: {
          part: [100, 0],
          count: outsidePutt,
        },
      };

      return stats;
    },
    toggleShowStatRow(id) {
      if (this.$store.state.utility.deviceWidth > 470) {
        let index = this.shownStatRows.indexOf("s" + id);

        if (index > -1) {
          this.shownStatRows.splice(index, 1);
        } else {
          this.shownStatRows.push("s" + id);
        }
      }
    },
    showThisStatRow(index) {
      return this.shownStatRows.indexOf(index) > -1;
    },
    poolDate(date) {
      if (this.$store.state.utility.deviceWidth > 1200) {
        return moment(date).format("dddd, DD MMMM [at] HH:mm");
      }
      return moment(date).format("ddd, DD MMM [at] HH:mm");
    },
    showPar(par) {
      if (par == 0) {
        return "E";
      } else if (par > 0) {
        return "+" + par;
      } else {
        return par;
      }
    },
    parCalc(playerPar, result) {
      if (result == 1) {
        return "ace";
      } else if (playerPar <= -3) {
        return "albatross";
      } else if (playerPar == -2) {
        return "eagle";
      } else if (playerPar == -1) {
        return "birdie";
      } else if (playerPar == 0) {
        return "par";
      } else if (playerPar == 1) {
        return "bogey";
      } else if (playerPar == 2) {
        return "doublebogey";
      } else if (playerPar == 3) {
        return "tripplebogey";
      } else if (playerPar >= 4) {
        return "above";
      }
      return "";
    },
    holeResult(hole, player) {
      let holeResult = player.results.filter(
        (result) => result.hole.id == hole.id
      );

      if (holeResult.length > 0) {
        return holeResult[0].score;
      } else {
        return "";
      }
    },
    playersInDivision(division) {
      return this.poolData[division];
    },
    loadData() {
      this.$axios({
        data: {
          query: `
          {
            pool(poolId:"${this.$router.currentRoute.params.poolId}"){
              leaderboard{
                id
                name
                type
                players{
                  playerId
                  place
                  score
                  par
                  isDnf
                  isDns
                  firstName
                  lastName
                  pdgaNumber
                  pdgaRating
                  results{
                    id
                    score
                    isCircleHit
                    isOutsidePutt
                    isInsidePutt
                    isOutOfBounds
                    hole{
                      id
                      number
                      par
                    }
                  }
                }
              }
              round{
                event{
                  rounds{
                    id
                    pools{
                      id
                    }
                  }
                  tour{
                    registrationMethod
                  }
                }
              }
              status
              areGroupsPublic
              startMethod
              date
              id
              layoutVersion{
                id
                layout{
                  id 
                  name
                  course{
                    name
                  }
                }
                holes{
                  id
                  number
                  par
                  name
                }
              }
            }
          }
          `,
        },
      }).then((result) => {
        this.poolData = result.data.data.pool;
        this.loading = false;
        setTimeout(() => {
          if (this.poolData.leaderboard != null) {
            this.tableLoaderSequence = this.poolData.leaderboard.length;
          }
        }, 1000);
      });
    },
  },
  watch: {
    $route() {
      this.loadData();
    },
  },
  computed: {
    resultsTableSize() {
      const layoutSize = this.poolData.layoutVersion.holes.length;
      if (layoutSize <= 9) {
        return "small";
      }
      if (layoutSize <= 18) {
        return "medium";
      }
      return "large";
    },
    poolLeaderboards() {
      const leaderBoards = this.poolData.leaderboard;
      const layout = this.poolData.layoutVersion.holes;

      const formattedLeaderboards = leaderBoards.map((division) => {
        division.players.forEach((player) => {
          player.roundPar = 0;
          player.roundTotal = 0;
          player.playerResults = [];

          layout.forEach((hole) => {
            let playerResult = player.results.find(
              (playedHole) => playedHole.hole.id == hole.id
            );

            let playerHoleResult = {
              result: null,
              score: null,
              isCircleHit: null,
              isConversion: null,
              isInsidePutt: null,
              isOutsidePutt: null,
              isOutOfBounds: null,
              playerPar: null,
            };

            if (playerResult) {
              let playerPar = playerResult.score - hole.par;

              player.roundPar += playerPar;
              player.roundTotal += playerResult.score;

              playerHoleResult.result = playerResult;
              playerHoleResult.score = playerResult.score;
              playerHoleResult.isCircleHit = playerResult.isCircleHit;
              playerHoleResult.isConversion =
                playerResult.isCircleHit && playerResult.isInsidePutt;
              playerHoleResult.isInsidePutt = playerResult.isInsidePutt;
              playerHoleResult.isOutsidePutt = playerResult.isOutsidePutt;
              playerHoleResult.isOutOfBounds = playerResult.isOutOfBounds;
              playerHoleResult.playerPar = playerPar;
            }

            player.stats = this.playerHoleStats(player.results);

            player.playerResults.push(playerHoleResult);
          });
        });

        division.players = orderBy(
          division.players,
          [this.utility.sortingCriteria],
          [this.utility.sortDirection]
        );

        return division;
      });

      return formattedLeaderboards;
    },
    colSpanCalc() {
      if (this.$store.state.utility.deviceWidth >= 1200) {
        return 6;
      } else {
        return 5;
      }
    },
    statRowColspanCalculator() {
      if (this.$store.state.utility.deviceWidth >= 1200) {
        return 4;
      } else {
        return 3;
      }
    },
    roundNumber() {
      for (var r = 0; r < this.poolData.round.event.rounds.length; r++) {
        for (
          var p = 0;
          p < this.poolData.round.event.rounds[r].pools.length;
          p++
        ) {
          if (
            this.poolData.round.event.rounds[r].pools[p].id == this.poolData.id
          ) {
            return r + 1;
          }
        }
      }
      return 3;
    },
    poolPar() {
      let poolPar = 0;
      for (var i = 0; i < this.poolData.layoutVersion.holes.length; i++) {
        poolPar += this.poolData.layoutVersion.holes[i].par;
      }
      return poolPar;
    },
  },
  mounted() {
    this.loadData();
  },
  beforeDestroy() {},
};
</script>


<style lang="scss" scoped>
@import "@/styles/Typography";

.auto-refresh {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 18px 0;
  padding: 0 12px;
  span {
    display: flex;
    align-items: center;
  }

  .default-toggle {
    margin-right: auto;
    margin-left: 8px;
  }
}
.division-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  animation: fade-in 0.75s;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.header {
  position: sticky;
  top: 0;
}

.watched {
  .banner-left,
  .banner-right {
    background: #fff7e5;
  }
}

.banner-left {
  height: 40px;
  border-radius: 6px 0 0 6px;
  background: $mist;
  padding-left: 16px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 3;
  justify-content: space-between;
  padding-right: 6px;
}
.banner-right {
  height: 40px;
  border-radius: 0 6px 6px 0;
  background: $mist;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 3;
}

.player-info-columns {
  z-index: 3;
  display: flex;

  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 100%
  );

  .active-sort {
    width: 100%;
    height: 2px;
    background: $midnight;
    position: absolute;
    bottom: -2px;
    left: 0;
  }

  .pos-column {
    flex: 1;
    text-align: center;

    b {
      position: relative;
    }
  }
  .player-column {
    flex: 5;
    p {
      padding-left: 18px;
    }
    b {
      position: relative;
    }
  }
  .total-column {
    flex: 1;
    text-align: center;
    b {
      position: relative;
    }

    .pool-par {
      margin-top: 4px;
      font-size: 0.75rem;
    }
  }
  .round-column {
    flex: 1;
    text-align: center;
    b {
      position: relative;
    }
    .pool-par {
      margin-top: 4px;
      font-size: 0.75rem;
    }
  }

  .player {
    border-bottom: 1px solid $mist;
  }
}
.player-results-columns {
  display: flex;
  padding-top: 24px;
  height: 84px;
  padding-left: 12px;
  z-index: 3;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 100%
  );

  .hole-info {
    width: 32px;
    min-width: 32px;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hole-par {
      margin-top: 8px;
      font-size: 14px;
    }
  }
}

.par-throw-toggle {
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100px;
  height: 22px;
  background: $blizzard;
  border-radius: 6px;
  cursor: pointer;
  user-select: none;

  .toggle-indicator {
    transition: all ease 0.3s;
    position: absolute;
    border-radius: 5px;
    height: 18px;
    width: 46px;
    top: 2px;
    left: calc(100% - 48px);
    background: white;
    z-index: 1;

    &.left {
      right: unset;
      left: 2px;
    }
  }

  .par-toggle {
    padding-left: 3px;
  }
  .throw-toggle {
    padding-right: 2px;
  }

  .par-toggle,
  .throw-toggle {
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 46px;
    font-size: 10px;
    @include Gilroy-Bold;
    color: white;
    transition: all ease 0.3s;
    cursor: pointer;

    &.active {
      cursor: default;
      color: $midnight;
    }
  }
}

.players {
  width: 100%;
}
.player {
  display: flex;
  flex-wrap: wrap;
  min-height: 36px;
  align-items: center;

  &.no-border {
    .position,
    .name,
    .total,
    .round {
      border-bottom: 1px solid transparent;
    }
  }
}

.position {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-bottom: 1px solid $fog;
}
.name {
  flex: 5;
  display: flex;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid $fog;
  cursor: pointer;
  overflow: hidden;

  svg {
    z-index: 0;
    margin-left: 6px;
    width: 10px;
    height: 6px;
  }
  p {
    padding-left: 18px;
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}

.total {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-bottom: 1px solid $fog;
}
.round {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-bottom: 1px solid $fog;
}
.results {
  display: flex;
  height: 36px;
  align-items: center;
  .result {
    background: white;
    display: flex;
    width: 32px;
    height: 32px;
    border: 1px solid $fog;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 4px;
    margin-right: 4px;
    @include Gilroy-Bold;

    &.ace {
      background-color: $ace;
      border-color: $ace;
    }
    &.albatross {
      background-color: $albatross;
      border-color: $albatross;
    }
    &.eagle {
      background-color: $eagle;
      border-color: $eagle;
    }
    &.birdie {
      background-color: $birdie;
      border-color: $birdie;
    }
    &.par {
      background-color: $par;
      border-color: $par;
    }
    &.bogey {
      background-color: $bogey;
      border-color: $bogey;
    }
    &.doublebogey {
      background-color: $doublebogey;
      border-color: $doublebogey;
    }
    &.tripplebogey {
      background-color: $tripplebogey;
      border-color: $tripplebogey;
    }
    &.above {
      background-color: $tripplebogey;
      border-color: $tripplebogey;
    }
  }
}

.player-stats {
  > div {
    display: flex;
    height: 36px;
    align-items: center;
  }

  .stat {
    width: 32px;
    height: 32px;
    margin-right: 4px;
    border: 1px solid $fog;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: $midnight;
    }
  }
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 599px) {
  .player {
    flex-wrap: wrap;
  }
  .name {
    p {
      padding-left: 4px;
    }
  }
  .division-container {
    padding: 0 12px;
  }
  .player-info {
    width: 100%;
    .banner-left {
      border-radius: 6px;
      height: 32px;
    }
  }
  .player-stats-info-portrait {
    width: 100%;
    margin-bottom: 18px;
    .main-stats {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: $mist;
      border-radius: 6px;
      padding: 16px 18px 18px 18px;
    }
    .watch-player {
      background: $mist;
      margin-top: 8px;
      border-radius: 6px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background ease 0.3s;

      &:hover {
        background: $fog;
      }

      &.unwatch {
        background: $sunrise;
      }
    }

    .portrait-stat {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .bar {
        margin: 10px 0 18px 0;
        width: 100%;
        height: 8px;
        border-radius: 4px;
        background: $fog;
        position: relative;

        .overlay {
          transition: all ease 0.3s;
          width: 0;
          background: $dusk;
          position: absolute;
          left: 0;
          top: 0;
          height: 8px;
          border-radius: 6px;
          background: linear-gradient(90deg, $sunrise 0%, $dusk 100%);
        }
      }
    }
    .op-ob {
      justify-content: flex-start;
      align-items: center;

      .outside-putts {
        margin-left: 7px;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $midnight;
        color: white;
      }
      .ob-text {
        margin-left: auto;
      }
      .out-of-bounds {
        position: relative;
        width: 30px;
        height: 17.32px;
        background-color: $midnight;
        margin: 8.66px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 7px;
        color: white;

        &.red {
          background: $dusk;

          &:before {
            border-bottom: 8.66px solid $dusk;
          }
          &.out-of-bounds:after {
            border-top: 8.66px solid $dusk;
          }
        }
      }

      .out-of-bounds:before,
      .out-of-bounds:after {
        content: "";
        position: absolute;
        width: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
      }

      .out-of-bounds:before {
        bottom: 100%;
        border-bottom: 8.66px solid $midnight;
      }

      .out-of-bounds:after {
        top: 100%;
        width: 0;
        border-top: 8.66px solid $midnight;
      }
    }
  }
  .player-results {
    display: none;
  }
  .player-stats-info {
    display: none;
  }

  .player-info-columns {
    position: sticky;
    top: 32px;
    padding-top: 16px;
    padding-bottom: 12px;
    height: unset;
    p {
      font-size: 0.625rem;
    }
    .player-column {
      p {
        padding-left: 4px;
      }
    }
  }
}

@media (min-width: 600px) {
  .banner-left {
    .par-throw-toggle {
      display: none;
    }
  }
  .player-info {
    width: 45%;
    .player-info-columns {
      .round-column {
        .pool-par {
          margin-top: 6px;
          font-size: 0.625rem;
        }
      }
    }

    &.small {
      .player-info-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.medium {
      .player-info-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.large {
      .player-info-columns {
        position: inherit;
        .player-column {
          flex: 4;
        }
      }
      .players {
        .name {
          flex: 4;
        }
      }
    }
  }
  .player-results {
    width: 55%;
    position: relative;

    &.small {
      .player-results-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.medium {
      .player-results-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.large {
      .player-results-columns {
        position: inherit;
        top: 0;

        .hole-info {
          min-width: 22px;
        }
      }
      .result-table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
      }
      .results {
        .result {
          min-width: 22px;
        }
      }
      .player-stats {
        .stat {
          min-width: 22px;
        }
      }
    }
    .player {
      padding-left: 12px;
      padding-right: 6px;
    }
  }
  .player-stats {
    margin-bottom: 12px;
  }
  .player {
    .player-stats-info {
      p {
        font-size: 0.625rem;
      }
    }
  }
  .player-stats-info {
    margin-bottom: 12px;
    animation: fade-in 0.5s;

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .left {
      .watch-player {
        p {
          font-size: 10px;
        }
      }
    }
    .stat-titles {
      > div {
        height: 28px;
      }
    }
  }
  .total-percentage {
    > div {
      height: 28px;
    }
  }
  .round-percentage {
    > div {
      height: 28px;
    }
  }
  .player-stats {
    width: 100%;
    animation: fade-in 0.5s;

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    > div {
      height: 28px;
    }
    .stat {
      height: 22px;
      width: auto;
      max-width: 32px;
      flex: 1;
      margin-right: 2px;

      span {
        width: 6px;
        height: 6px;
        border-radius: 3px;
      }
    }
  }
  .banner-left {
    height: 32px;
    border-radius: 0;
  }

  .banner-right {
    height: 32px;
    border-radius: 0;
  }
  .position {
    height: 28px;
  }
  .name {
    height: 28px;

    svg {
      width: 9px;
      height: 5px;
    }
  }
  .total {
    height: 28px;
  }
  .round {
    height: 28px;
  }
  .player {
    min-height: 28px;
    p {
      font-size: 0.75rem;
    }

    .name {
      p {
        padding-left: 4px;
      }
    }
  }
  .player-info-columns {
    position: sticky;
    top: 32px;
    padding-top: 16px;
    padding-bottom: 8px;
    height: 52px;
    p {
      font-size: 0.625rem;
    }
    .player-column {
      p {
        padding-left: 4px;
      }
    }
  }
  .player-results-columns {
    position: sticky;
    top: 32px;
    padding-top: 16px;
    padding-bottom: 8px;
    height: 52px;
    display: flex;
    width: 100%;
    padding-right: 6px;

    p {
      font-size: 0.625rem;
    }
    .hole-info {
      width: auto;
      flex: 1;
      margin-right: 2px;
      min-width: unset;
      max-width: 22px;
      .hole-par {
        font-size: 0.625rem;
        margin-top: 6px;
      }
    }
  }

  .results {
    justify-content: flex-start;
    width: 100%;
    height: 28px;
    .result {
      font-size: 0.75rem;
      margin-right: 2px;
      height: 22px;
      flex: 1;
      width: auto;
      max-width: 22px;
    }
  }

  .player-info {
    width: 45%;

    &.small {
      width: 50%;
      .player-info-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.medium {
      width: 45%;

      .player-info-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.large {
      width: 38%;

      .player-info-columns {
        .player-column {
          flex: 4;
        }
      }
      .players {
        .name {
          flex: 4;
        }
      }
    }
  }
  .player-results {
    width: 55%;
    &.small {
      width: 50%;
      .player-results-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.medium {
      width: 55%;

      .player-results-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.large {
      width: 62%;

      .result-table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
    .player {
      padding-left: 12px;
      padding-right: 6px;
    }
  }
  .player-stats-info-portrait {
    display: none;
  }
  .player-stats-info {
    width: 100%;
    display: flex;

    .left {
      flex: 3.5;
      display: flex;
      align-items: flex-end;
      padding-bottom: 4px;

      .watch-player {
        background: $mist;
        cursor: pointer;
        height: 28px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        border-radius: 6px;
        transition: background ease 0.3s;
        margin-left: 12px;
        white-space: nowrap;

        &:hover {
          background: $fog;
        }
        &.unwatch {
          background: $sunrise;
        }
      }
    }

    .stat-titles {
      flex: 3.5;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      > div {
        display: flex;
        align-items: center;
      }
    }

    .total-percentage {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      > div {
        display: flex;
        align-items: center;
      }
    }
    .round-percentage {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  @media (min-width: 1200px) {
    .auto-refresh {
      padding: 0;
      margin: 0 0 32px 0;
    }
    .division-container {
      margin-bottom: 48px;
    }
    .player-stats {
      margin-bottom: 42px;
      > div {
        height: 36px;
      }
      .stat {
        height: 32px;
        min-width: 32px;
        margin-right: 4px;
        span {
          width: 8px;
          height: 8px;
          border-radius: 4px;
        }
      }
    }
    .player-stats-info {
      margin-bottom: 42px;
      .left {
        .watch-player {
          height: 32px;
          padding: 0 12px;
          p {
            font-size: 0.875rem;
          }
        }
      }
      .stat-titles {
        > div {
          height: 36px;
        }

        p {
          font-size: 1rem;
        }
      }
      .total-percentage {
        > div {
          height: 36px;
        }
        p {
          font-size: 1rem;
        }
      }
      .round-percentage {
        > div {
          height: 36px;
        }
        p {
          font-size: 1rem;
        }
      }
    }
    .player {
      min-height: 36px;

      p {
        font-size: 1rem;
      }

      .position,
      .name,
      .total,
      .round {
        height: 36px;
      }
    }
    .results {
      height: 36px;
      .result {
        height: 32px;
        min-width: 32px;
        margin-right: 4px;
        p {
          font-size: 1rem;
        }
      }
    }
    .banner-left {
      height: 40px;
      border-radius: 6px 0 0 6px;
    }
    .banner-right {
      height: 40px;
      border-radius: 0 6px 6px 0;
    }
    .par-throw-toggle {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 120px;
      height: 28px;
      background: $blizzard;
      border-radius: 6px;
      cursor: pointer;
      user-select: none;

      .toggle-indicator {
        transition: all ease 0.3s;
        position: absolute;
        border-radius: 5px;
        height: 24px;
        width: 56px;
        top: 2px;
        left: calc(100% - 58px);
        background: white;
        z-index: 1;

        &.left {
          right: unset;
          left: 2px;
        }
      }

      .par-toggle,
      .throw-toggle {
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        font-size: 12px;
        @include Gilroy-Bold;
        color: white;
        transition: all ease 0.3s;
        cursor: pointer;

        &.active {
          cursor: default;
          color: $midnight;
        }
      }
    }

    .player-results {
      &.small {
        .player-results-columns {
          top: 40px;
        }
      }
      &.medium {
        .player-results-columns {
          top: 40px;
        }
      }
      &.large {
        .results {
          .result {
            min-width: unset;
            width: 32px;
            max-width: unset;
            p {
              font-size: 0.875rem;
            }
          }
        }
        .result-table-wrapper {
          overflow: hidden;
        }
        .player-results-columns {
          top: 40px;
          .hole-info {
            min-width: unset;
            max-width: unset;
          }
        }
      }
    }

    .player-results-columns {
      padding-top: 24px;
      height: 78px;

      p {
        font-size: 0.875rem;
      }
      .hole-info {
        min-width: 32px;
        margin-right: 4px;
        .hole-par {
          margin-top: 10px;
          font-size: 0.75rem;
        }
      }
    }
    .player-info {
      &.small {
        .player-info-columns {
          top: 40px;
        }
      }
      &.medium {
        .player-info-columns {
          top: 40px;
        }
      }

      &.large {
        .player-info-columns {
          top: 40px;
        }
      }
    }
    .player-info-columns {
      padding-top: 24px;
      height: 78px;
      top: 40px;

      .round-column {
        .pool-par {
          margin-top: 10px !important;
          font-size: 0.75rem !important;
        }
      }

      p {
        font-size: 0.875rem;
      }

      .player-column {
        min-height: 40px;
      }
      .pos-column {
        min-height: 40px;
      }
    }
  }
}
</style>