<template>
  <div class="live-pool" v-if="loaded">
    <div class="live-controls">
      <div class="auto-refresh">
        <div class="text">
          <p><b>Auto Update</b></p>
          <p class="super-small">
            Updated: {{ lastUpdated(scoresLastLoaded) }}
          </p>
        </div>
        <DefaultToggle v-model="autoReFresh" />
      </div>
      <div class="control-spacer"></div>
      <TjingTextOptionToggle
        class="par-throw-toggle"
        :width="120"
        leftValue="Par"
        rightValue="Throws"
        @left="utility.showPars = true"
        @right="utility.showPars = false"
      />
    </div>

    <div
      class="division-container"
      v-for="division in poolLeaderboards"
      v-if="division.players.length > 0"
      :class="{ watched: division.type == 'WATCHED' }"
      :key="division.type"
    >
      <div class="player-info" :class="resultsTableSize">
        <div class="banner-left">
          <p>
            <b>{{ division.name }}</b> ({{ division.players.length }})
          </p>
        </div>
        <div class="player-info-columns">
          <div class="pos-column">
            <p><b>POS</b></p>
          </div>
          <div class="player-column">
            <p><b>PLAYER</b></p>
          </div>
          <div class="thru-column">
            <p><b>THRU</b></p>
          </div>
          <div class="total-column">
            <p><b>TOT</b></p>
            <p v-if="utility.showPars && roundNumber == 1" class="pool-par">
              {{ poolPar() }}
            </p>
          </div>
          <div class="round-column" v-if="roundNumber > 1">
            <p><b>RD</b></p>
            <p v-if="utility.showPars" class="pool-par">{{ poolPar() }}</p>
          </div>
        </div>
        <div class="players">
          <transition-group
            :name="utility.useListAnimation ? 'list-complete' : ''"
          >
            <div
              class="player"
              :class="{
                'no-border': shownStatRows.includes(player.playerId),
              }"
              :style="{ 'animation-delay': 0.05 * index + 's' }"
              v-for="(player, index) in division.players"
              :key="division.type + player.playerId"
            >
              <div class="position">
                <p>{{ player.place }}</p>
              </div>
              <div class="name" @click="toggleStatRows(player.playerId)">
                <p>
                  <b>{{ player.firstName }} {{ player.lastName }}</b>
                </p>
                <Chevron2
                  :direction="
                    shownStatRows.includes(player.playerId) ? 'up' : 'down'
                  "
                />
              </div>
              <div class="thru">
                <p>
                  {{ thruCount(player.playerResults, poolData.layoutVersion) }}
                </p>
              </div>
              <div class="total">
                <p v-if="utility.showPars">
                  <b>{{ parFormatter(player.totalPar) }}</b>
                </p>
                <p v-else>
                  <b>{{ player.totalScore }}</b>
                </p>
              </div>
              <div class="round" v-if="roundNumber > 1">
                <p v-if="utility.showPars">
                  {{ parFormatter(player.roundPar) }}
                </p>
                <p v-else>{{ player.roundTotal }}</p>
              </div>
              <div
                class="player-stats-info-portrait"
                v-if="shownStatRows.includes(player.playerId)"
              >
                <div class="main-stats">
                  <div class="circle-hits portrait-stat">
                    <p>
                      <b>Circle hits</b> ({{ player.stats.circleHits.count }})
                    </p>
                    <p>
                      <b>{{ toPercentage(player.stats.circleHits.part) }}</b
                      >%
                    </p>
                    <div class="bar">
                      <span
                        class="overlay"
                        :class="{ full: player.stats.circleHits.part == 1 }"
                        :style="{
                          width:
                            toPercentage(player.stats.circleHits.part) + '%',
                        }"
                      ></span>
                    </div>
                  </div>
                  <div class="conversions portrait-stat">
                    <p>
                      <b>Conversions</b> ({{
                        player.stats.circleHitConversions.count || 0
                      }})
                    </p>
                    <p>
                      <b>{{
                        toPercentage(player.stats.circleHitConversions.part)
                      }}</b
                      >%
                    </p>
                    <div class="bar">
                      <span
                        class="overlay"
                        :class="{
                          full: player.stats.circleHitConversions.part == 1,
                        }"
                        :style="{
                          width:
                            toPercentage(
                              player.stats.circleHitConversions.part
                            ) + '%',
                        }"
                      ></span>
                    </div>
                  </div>
                  <div class="inside-putts portrait-stat">
                    <p>
                      <b>Inside putts</b> ({{ player.stats.insidePutts.count }})
                    </p>
                    <p>
                      <b>{{ toPercentage(player.stats.insidePutts.part) }}</b
                      >%
                    </p>
                    <div class="bar">
                      <span
                        class="overlay"
                        :class="{ full: player.stats.insidePutts.part == 1 }"
                        :style="{
                          width:
                            toPercentage(player.stats.insidePutts.part) + '%',
                        }"
                      ></span>
                    </div>
                  </div>
                  <div class="op-ob portrait-stat">
                    <p><b>Outside putts</b></p>
                    <div
                      class="outside-putts"
                      :class="{ empty: player.stats.outsidePutts.count == 0 }"
                    >
                      <p>
                        <b>{{ player.stats.outsidePutts.count }}</b>
                      </p>
                    </div>
                    <p class="ob-text">
                      <b>OB</b>
                    </p>
                    <div
                      class="out-of-bounds"
                      :class="{ red: player.stats.outOfBounds.count > 0 }"
                    >
                      <p>
                        <b>{{ player.stats.outOfBounds.count }}</b>
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  class="watch-player unwatch"
                  v-if="
                    watchedPlayers.some(
                      (watched) => watched.playerId == player.playerId
                    )
                  "
                >
                  <p @click="togglePlayerWatcher(player)">
                    <b>Stop watching</b>
                  </p>
                </div>
                <div class="watch-player" v-else>
                  <p @click="togglePlayerWatcher(player)">
                    <b>Watch player</b>
                  </p>
                </div>
              </div>
              <div
                class="player-stats-info"
                v-if="shownStatRows.includes(player.playerId)"
              >
                <div class="left" :style="{ flex: roundNumber == 1 ? 4 : 5 }">
                  <div
                    class="watch-player unwatch"
                    v-if="
                      watchedPlayers.some(
                        (watched) => watched.playerId == player.playerId
                      )
                    "
                  >
                    <p @click="togglePlayerWatcher(player)">
                      <b>Stop watching</b>
                    </p>
                  </div>
                  <div class="watch-player" v-else>
                    <p @click="togglePlayerWatcher(player)">
                      <b>Watch player</b>
                    </p>
                  </div>
                </div>
                <div class="stat-titles">
                  <div class="circle-hits">
                    <p>Circle hits:</p>
                  </div>
                  <div class="conversions">
                    <p>Conversions:</p>
                  </div>
                  <div class="inside-putts">
                    <p>Inside putts:</p>
                  </div>
                  <div class="outside-putts">
                    <p>Outside putts:</p>
                  </div>
                  <div class="out-of-bounds">
                    <p>Out of Bounds:</p>
                  </div>
                </div>
                <div class="total-percentage">
                  <div>
                    <p v-if="roundNumber == 1">
                      <b>{{ toPercentage(player.stats.circleHits.part) }}%</b>
                    </p>
                    <p v-else>
                      {{ toPercentage(player.stats.circleHits.part) }}%
                    </p>
                  </div>
                  <div>
                    <p v-if="roundNumber == 1">
                      <b
                        >{{
                          toPercentage(player.stats.circleHitConversions.part)
                        }}%</b
                      >
                    </p>
                    <p v-else>
                      {{
                        toPercentage(player.stats.circleHitConversions.part)
                      }}%
                    </p>
                  </div>
                  <div>
                    <p v-if="roundNumber == 1">
                      <b>{{ toPercentage(player.stats.insidePutts.part) }}%</b>
                    </p>
                    <p v-else>
                      {{ toPercentage(player.stats.insidePutts.part) }}%
                    </p>
                  </div>
                  <div>
                    <p v-if="roundNumber == 1">
                      <b>{{ player.stats.outsidePutts.count }}</b>
                    </p>
                    <p v-else>
                      {{ player.stats.outsidePutts.count }}
                    </p>
                  </div>
                  <div>
                    <p v-if="roundNumber == 1">
                      <b>{{ player.stats.outOfBounds.count }}</b>
                    </p>
                    <p v-else>
                      {{ player.stats.outOfBounds.count }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
      <div class="player-results" :class="resultsTableSize">
        <div class="banner-right"></div>
        <div class="result-table-wrapper">
          <div class="player-results-columns">
            <div class="hole-info" v-for="hole in poolData.layoutVersion.holes">
              <p class="hole-number">
                <b>{{ hole.name || hole.number }}</b>
              </p>
              <p class="hole-par">{{ hole.par }}</p>
            </div>
          </div>
          <div class="players">
            <transition-group
              :name="utility.useListAnimation ? 'list-complete' : ''"
            >
              <div
                class="player"
                :style="{ 'animation-delay': 0.05 * index + 's' }"
                v-for="(player, index) in division.players"
                :key="division.type + player.playerId"
              >
                <div class="results">
                  <div
                    v-if="result"
                    class="result"
                    :class="[
                      parCalc(result.playerPar, result.score),
                      { updated: result.updated },
                    ]"
                    v-for="result in player.playerResults"
                  >
                    <p>
                      <b v-if="result.score">{{ result.score }}</b>
                    </p>
                  </div>
                  <div class="result empty" v-else></div>
                </div>
                <div
                  class="player-stats"
                  v-if="shownStatRows.includes(player.playerId)"
                >
                  <div class="circle-hits">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isCircleHit"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                  <div class="conversions">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isConversion"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                  <div class="inside-putts">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isInsidePutt"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                  <div class="outside-putts">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isOutsidePutt"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                  <div class="out-of-bounds">
                    <div
                      v-if="stat"
                      class="stat"
                      v-for="stat in player.playerResults"
                    >
                      <span v-if="stat.isOutOfBounds"></span>
                    </div>
                    <div v-else class="stat"></div>
                  </div>
                </div>
              </div>
            </transition-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import Chevron2 from "@/assets/icons/Chevron2";
import DefaultToggle from "@/components/UIElements/DefaultToggle";
import TjingTextOptionToggle from "@/components/UIElements/TjingTextOptionToggle";
export default {
  name: "PublicEventLivePool",
  components: {
    Chevron2,
    DefaultToggle,
    TjingTextOptionToggle,
  },
  data() {
    return {
      utility: {
        showPars: true,
        animationDelay: true,
        useListAnimation: false,
      },
      existingScores: [],
      leaderboard: [],
      loaded: false,
      shownStatRows: [],
      poolData: null,
      watchedPlayers: [],
      autoReFresh: false,
      autoReFreshInterval: null,
      windowFocused: true,
      scoresLastLoaded: null,
      poolLeaderboards: null,
    };
  },
  watch: {
    windowFocused: function (newVal, oldVal) {
      if (newVal) {
        if (this.scoresLastLoaded.isBefore(moment().subtract(30, "seconds"))) {
          this.loadData();
        }
      }
    },
    autoReFresh: function (newVal, oldVal) {
      if (newVal) {
        this.loadData();
        localStorage.setItem(
          "autoReFreshEnabled",
          JSON.stringify({ enabled: true, createdAt: moment().toISOString() })
        );
        this.autoReFreshInterval = setInterval(() => {
          if (!document.hidden) {
            this.loadData();
          }
        }, 10000);
      } else {
        clearInterval(this.autoReFreshInterval);
        localStorage.removeItem("autoReFreshEnabled");
      }
    },
  },
  computed: {
    roundNumber() {
      let roundNumber = 1;
      this.poolData.round.event.rounds.forEach((round, roundIndex) => {
        round.pools.forEach((pool) => {
          if (pool.id == this.poolData.id) {
            roundNumber = roundIndex + 1;
          }
        });
      });

      return roundNumber;
    },
    resultsTableSize() {
      const layoutSize = this.poolData.layoutVersion.holes.length;
      if (layoutSize <= 9) {
        return "small";
      }
      if (layoutSize <= 18) {
        return "medium";
      }
      return "large";
    },
  },
  methods: {
    lastUpdated(date) {
      return date.format("HH:mm");
    },
    focusFunction(e) {
      if (e.type == "blur") {
        this.windowFocused = false;
      } else if (e.type == "focus") {
        this.windowFocused = true;
      }
    },
    togglePlayerWatcher(player) {
      let watched = false;

      watched = this.watchedPlayers.some(
        (watchedPlayer) => watchedPlayer.playerId == player.playerId
      );

      if (watched == false) {
        this.watchedPlayers.push({
          playerId: player.playerId,
          createdAt: moment().toISOString(),
        });
      } else {
        this.watchedPlayers = this.watchedPlayers.filter((watchedPlayer) => {
          return watchedPlayer.playerId != player.playerId;
        });
      }

      this.toggleStatRows(player.playerId);
      this.$nextTick(() => {
        this.saveWatchedPlayers();
        this.loadData();
      });
    },
    saveWatchedPlayers() {
      localStorage.setItem(
        "LivePlayersWatched",
        JSON.stringify(this.watchedPlayers)
      );
    },
    poolPar() {
      let poolPar = 0;
      for (var i = 0; i < this.poolData.layoutVersion.holes.length; i++) {
        poolPar += this.poolData.layoutVersion.holes[i].par;
      }
      return poolPar;
    },
    parCalc(playerPar, result) {
      if (result == 1) {
        return "ace";
      } else if (playerPar <= -3) {
        return "albatross";
      } else if (playerPar == -2) {
        return "eagle";
      } else if (playerPar == -1) {
        return "birdie";
      } else if (playerPar == 0) {
        return "par";
      } else if (playerPar == 1) {
        return "bogey";
      } else if (playerPar == 2) {
        return "doublebogey";
      } else if (playerPar == 3) {
        return "tripplebogey";
      } else if (playerPar >= 4) {
        return "above";
      }
      return "";
    },
    toPercentage(part) {
      return (part * 100).toFixed(0);
    },
    parFormatter(par) {
      if (par == 0) {
        return "E";
      } else if (par > 0) {
        return "+" + par;
      } else {
        return par;
      }
    },
    createLeaderboards() {
      this.utility.useListAnimation = true;
      const leaderBoards = this.poolData.livescore;
      const layout = this.poolData.layoutVersion.holes;
      let watchedPlayers = [];

      const formattedLeaderboards = leaderBoards.map((division) => {
        division.players.forEach((player) => {
          player.roundPar = 0;
          player.roundTotal = 0;
          player.playerResults = [];

          layout.forEach((hole) => {
            let playerResult = player.results.find(
              (playedHole) => playedHole.holeId == hole.id
            );

            let playerHoleResult = {
              result: null,
              score: null,
              isCircleHit: null,
              isConversion: null,
              isInsidePutt: null,
              isOutsidePutt: null,
              isOutOfBounds: null,
              playerPar: null,
              updated: false,
            };

            if (playerResult) {
              if (
                !this.existingScores.some(
                  (score) =>
                    score.id == playerResult.id &&
                    score.score == playerResult.score
                )
              ) {
                this.existingScores.push({
                  id: playerResult.id,
                  score: playerResult.score,
                });
                playerHoleResult.updated = true;
              }

              let playerPar = playerResult.score - hole.par;

              player.roundPar += playerPar;
              player.roundTotal += playerResult.score;

              playerHoleResult.result = playerResult;
              playerHoleResult.score = playerResult.score;
              playerHoleResult.isCircleHit = playerResult.isCircleHit;
              playerHoleResult.isConversion =
                playerResult.isCircleHit && playerResult.isInsidePutt;
              playerHoleResult.isInsidePutt = playerResult.isInsidePutt;
              playerHoleResult.isOutsidePutt = playerResult.isOutsidePutt;
              playerHoleResult.isOutOfBounds = playerResult.isOutOfBounds;
              playerHoleResult.playerPar = playerPar;
            }

            player.playerResults.push(playerHoleResult);
          });
        });

        return division;
      });

      formattedLeaderboards.forEach((division) => {
        division.players.forEach((player) => {
          if (
            this.watchedPlayers.some(
              (watched) => watched.playerId == player.playerId
            )
          ) {
            watchedPlayers.push(player);
          }
        });
      });

      const watchedDivision = {
        id: "watchedDivision",
        name: "Watched players",
        type: "WATCHED",
        players: watchedPlayers,
      };

      return [watchedDivision, ...formattedLeaderboards];
    },
    toggleStatRows(id) {
      this.utility.useListAnimation = false;
      if (this.shownStatRows.includes(id)) {
        this.shownStatRows = this.shownStatRows.filter(
          (playerId) => id != playerId
        );
      } else {
        this.shownStatRows.push(id);
      }
    },
    setLayoutLength(length) {
      this.utility.layoutLength = length;
      this.loadData();
    },
    thruCount(results, layout) {
      const thruCount = results.filter(
        (result) => result?.score != null
      ).length;
      const layoutLength = layout.holes.length;

      if (thruCount == layoutLength) {
        return "F";
      }
      return results.filter((result) => result?.score != null).length;
    },

    async loadData() {
      const liveScores = await this.$axios({
        data: {
          query: `
          {
            pool(poolId:"${this.$router.currentRoute.params.poolId}"){
              status
              id
              areGroupsPublic
              round{
                id
                pools{
                  id
                }
                event{
                  id
                  endDate
                  rounds{
                    id
                    pools{
                      id
                    }
                  }
                  registrations{
                    id
                    status
                    player{
                      id
                    }
                    user{
                      id
                      profile{
                        club
                        pdgaNumber
                        pdgaRating
                      }
                    }
                  }
                }
              }
              layoutVersion{
                id
                layout{
                  id
                  name
                  course{
                    name
                  }
                }
                holes{
                  id
                  number
                  name
                  par
                }
              }
              livescore{
                id
                name
                type
                players{
                	playerId
                  place
                  firstName
                  lastName
                  isDnf
                  isDns
                  totalScore
                  totalPar
                  stats{
                    circleHitConversions{
                      part
                      count
                    }
                    circleHits{
                      part
                      count
                    }
                    insidePutts{
                      part
                      count
                    }
                    outsidePutts{
                      count
                    }
                    outOfBounds{
                      count
                    }
                  }
                  results{
                    id
                    score
                    holeId
                    isCircleHit
                    isInsidePutt
                    isOutsidePutt
                    isOutOfBounds
                  }
                }
              }
            }
          }
          `,
        },
      });

      this.poolData = liveScores.data.data.pool;
      this.loaded = true;
      this.scoresLastLoaded = moment();

      if (localStorage.getItem("LivePlayersWatched")) {
        let savedData = JSON.parse(localStorage.getItem("LivePlayersWatched"));

        savedData = savedData.filter((player) => {
          return moment(player.createdAt).isAfter(
            moment().subtract(14, "days")
          );
        });

        this.watchedPlayers = savedData;

        localStorage.setItem(
          "LivePlayersWatched",
          JSON.stringify(this.watchedPlayers)
        );
      } else {
        localStorage.setItem("LivePlayersWatched", JSON.stringify([]));
      }

      this.poolLeaderboards = this.createLeaderboards();
    },
  },

  beforeDestroy() {
    window.removeEventListener("blur", this.focusFunction);
    window.removeEventListener("focus", this.focusFunction);
    clearInterval(this.autoReFreshInterval);
  },
  mounted() {
    this.loadData();
    if (localStorage.getItem("autoReFreshEnabled")) {
      const autoRefreshInfo = JSON.parse(
        localStorage.getItem("autoReFreshEnabled")
      );
      if (
        moment(autoRefreshInfo.createdAt).isBefore(
          moment().subtract(1, "days")
        ) ||
        autoRefreshInfo.enabled == false
      ) {
        localStorage.removeItem("autoReFreshEnabled");
      } else {
        this.autoReFresh = true;
      }
    }

    window.addEventListener("blur", this.focusFunction);
    window.addEventListener("focus", this.focusFunction);
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/Typography";
.live-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 12px;
  width: 100%;
}
.auto-refresh {
  display: flex;
  .text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-right: 8px;
  }
  .super-small {
    font-size: 0.625rem;
  }
}

.division-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  animation: fade-in 0.75s;

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.header {
  position: sticky;
  top: 0;
}

.watched {
  .banner-left,
  .banner-right {
    background: #fff7e5;
  }
}

.banner-left {
  height: 40px;
  border-radius: 6px 0 0 6px;
  background: $mist;
  padding-left: 16px;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 3;
  justify-content: space-between;
  padding-right: 6px;
}
.banner-right {
  height: 40px;
  border-radius: 0 6px 6px 0;
  background: $mist;
  padding-right: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: sticky;
  top: 0;
  z-index: 3;
}

.player-info-columns {
  z-index: 3;
  display: flex;

  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 100%
  );

  .pos-column {
    flex: 1;
    text-align: center;
  }
  .player-column {
    flex: 5;
    p {
      padding-left: 18px;
    }
  }
  .thru-column {
    flex: 1;
    text-align: center;
  }
  .total-column {
    flex: 1;
    text-align: center;

    .pool-par {
      margin-top: 6px;
      font-size: 0.625rem;
    }
  }
  .round-column {
    flex: 1;
    text-align: center;
    .pool-par {
      margin-top: 6px;
      font-size: 0.625rem;
    }
  }

  .player {
    border-bottom: 1px solid $mist;
  }
}
.player-results-columns {
  display: flex;
  padding-top: 24px;
  height: 84px;
  padding-left: 12px;
  z-index: 3;
  background: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 15%,
    rgba(255, 255, 255, 1) 100%
  );

  .hole-info {
    width: 32px;
    min-width: 32px;
    margin-right: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .hole-par {
      margin-top: 8px;
      font-size: 14px;
    }
  }
}

.players {
  width: 100%;
}
.player {
  display: flex;
  flex-wrap: wrap;
  min-height: 36px;
  align-items: center;

  &.no-border {
    .position,
    .name,
    .thru,
    .total,
    .round {
      border-bottom: 1px solid transparent;
    }
  }
}

.position {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-bottom: 1px solid $fog;
}
.name {
  flex: 5;
  display: flex;
  align-items: center;
  height: 36px;
  border-bottom: 1px solid $fog;
  cursor: pointer;
  overflow: hidden;

  svg {
    z-index: 0;
    margin-left: 6px;
    width: 10px;
    height: 6px;
  }
  p {
    padding-left: 18px;
    max-width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
}
.thru {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-bottom: 1px solid $fog;
}
.total {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-bottom: 1px solid $fog;
}
.round {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  border-bottom: 1px solid $fog;
}
.results {
  display: flex;
  height: 36px;
  align-items: center;
  .result {
    background: white;
    display: flex;
    width: 32px;
    height: 32px;
    border: 1px solid $fog;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 4px;
    margin-right: 4px;
    @include Gilroy-Bold;

    &.ace {
      background-color: $ace;
      border-color: $ace;
    }
    &.albatross {
      background-color: $albatross;
      border-color: $albatross;
    }
    &.eagle {
      background-color: $eagle;
      border-color: $eagle;
    }
    &.birdie {
      background-color: $birdie;
      border-color: $birdie;
    }
    &.par {
      background-color: $par;
      border-color: $par;
    }
    &.bogey {
      background-color: $bogey;
      border-color: $bogey;
    }
    &.doublebogey {
      background-color: $doublebogey;
      border-color: $doublebogey;
    }
    &.tripplebogey {
      background-color: $tripplebogey;
      border-color: $tripplebogey;
    }
    &.above {
      background-color: $tripplebogey;
      border-color: $tripplebogey;
    }

    &.updated {
      animation: bounce-in 0.75s;

      b {
        transition: opacity 0.5s ease;
        transition-delay: 0.5s;
        opacity: 1;
      }
    }
  }
}

.player-stats {
  > div {
    display: flex;
    height: 36px;
    align-items: center;
  }

  .stat {
    width: 32px;
    height: 32px;
    margin-right: 4px;
    border: 1px solid $fog;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 22px;

    span {
      width: 8px;
      height: 8px;
      border-radius: 4px;
      background: $midnight;
    }
  }
}

.list-complete-item {
  transition: all 1s;
  display: inline-block;
  margin-right: 10px;
}
.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(100%);
}
.list-complete-leave-active {
  position: absolute;
}

.list-complete-move {
  transition: transform 1s;
  transition-delay: 0.8s !important;
}

@keyframes bounce-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes bounce-in-text {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.25;
  }
  40% {
    opacity: 0.75;
  }
  60% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@media (max-width: 599px) {
  .player {
    flex-wrap: wrap;
  }
  .name {
    p {
      padding-left: 4px;
    }
  }
  .division-container {
    padding: 0 12px;
  }
  .player-info {
    width: 100%;
    .banner-left {
      border-radius: 6px;
      height: 32px;
    }
  }
  .player-stats-info-portrait {
    width: 100%;
    margin-bottom: 18px;
    .main-stats {
      width: 100%;
      display: flex;
      flex-direction: column;
      background: $mist;
      border-radius: 6px;
      padding: 16px 18px 18px 18px;
    }
    .watch-player {
      background: $mist;
      margin-top: 8px;
      border-radius: 6px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: background ease 0.3s;

      &:hover {
        background: $fog;
      }

      &.unwatch {
        background: $sunrise;
      }
    }

    .portrait-stat {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .bar {
        margin: 10px 0 18px 0;
        width: 100%;
        height: 8px;
        border-radius: 4px;
        background: $fog;
        position: relative;

        .overlay {
          transition: all ease 0.3s;
          width: 0;
          background: $dusk;
          position: absolute;
          left: 0;
          top: 0;
          height: 8px;
          border-radius: 6px;
          background: linear-gradient(90deg, $sunrise 0%, $dusk 100%);

          &.full {
            background: $twilight;
          }
        }
      }
    }
    .op-ob {
      justify-content: flex-start;
      align-items: center;

      .outside-putts {
        margin-left: 7px;
        height: 30px;
        width: 30px;
        border-radius: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: $midnight;
        color: white;

        &.empty {
          background: $fog;
          color: $midnight;
        }
      }
      .ob-text {
        margin-left: auto;
      }
      .out-of-bounds {
        position: relative;
        width: 30px;
        height: 17.32px;
        background-color: $fog;
        margin: 8.66px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 7px;
        color: $midnight;
        transform: rotate(30deg);
        p {
          transform: rotate(-30deg);
        }
        &.red {
          background: $dusk;

          &:before {
            border-bottom: 8.66px solid $dusk;
          }
          &.out-of-bounds:after {
            border-top: 8.66px solid $dusk;
          }
        }
      }

      .out-of-bounds:before,
      .out-of-bounds:after {
        content: "";
        position: absolute;
        width: 0;
        border-left: 15px solid transparent;
        border-right: 15px solid transparent;
      }

      .out-of-bounds:before {
        bottom: 100%;
        border-bottom: 8.66px solid $fog;
      }

      .out-of-bounds:after {
        top: 100%;
        width: 0;
        border-top: 8.66px solid $fog;
      }
    }
  }
  .player-results {
    display: none;
  }
  .player-stats-info {
    display: none;
  }

  .player-info-columns {
    position: sticky;
    top: 32px;
    padding-top: 16px;
    padding-bottom: 12px;
    height: unset;
    p {
      font-size: 0.625rem;
    }
    .player-column {
      p {
        padding-left: 4px;
      }
    }
  }
}

@media (min-width: 600px) {
  .banner-left {
    .par-throw-toggle {
      display: none;
    }
  }
  .player-info {
    width: 45%;

    &.small {
      .player-info-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.medium {
      .player-info-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.large {
      .player-info-columns {
        position: inherit;
        .player-column {
          flex: 4;
        }
      }
      .players {
        .name {
          flex: 4;
        }
      }
    }
  }
  .player-results {
    width: 55%;
    position: relative;

    &.small {
      .player-results-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.medium {
      .player-results-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.large {
      .player-results-columns {
        position: inherit;
        top: 0;

        .hole-info {
          min-width: 22px;
        }
      }
      .result-table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
      }
      .results {
        .result {
          min-width: 22px;
        }
      }
      .player-stats {
        .stat {
          min-width: 22px;
        }
      }
    }
    .player {
      padding-left: 12px;
      padding-right: 6px;
    }
  }
  .player-stats {
    margin-bottom: 12px;
  }

  .player-stats-info {
    margin-bottom: 12px;
    animation: fade-in 0.5s;

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    .left {
      .watch-player {
        p {
          font-size: 10px;
        }
      }
    }
    .stat-titles {
      > div {
        height: 28px;
      }
    }
  }
  .total-percentage {
    > div {
      height: 28px;
    }
  }
  .round-percentage {
    > div {
      height: 28px;
    }
  }
  .player-stats {
    width: 100%;
    animation: fade-in 0.5s;

    @keyframes fade-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
    > div {
      height: 28px;
    }
    .stat {
      height: 22px;
      width: auto;

      flex: 1;
      margin-right: 2px;

      span {
        width: 6px;
        height: 6px;
        border-radius: 3px;
      }
    }
  }
  .banner-left {
    height: 32px;
    border-radius: 0;
  }

  .banner-right {
    height: 32px;
    border-radius: 0;
  }
  .position {
    height: 28px;
  }
  .name {
    height: 28px;

    svg {
      width: 9px;
      height: 5px;
    }
  }
  .thru {
    height: 28px;
  }
  .total {
    height: 28px;
  }
  .round {
    height: 28px;
  }
  .player {
    min-height: 28px;
    p {
      font-size: 0.75rem;
    }

    .name {
      p {
        padding-left: 4px;
      }
    }
  }
  .player-info-columns {
    position: sticky;
    top: 32px;
    padding-top: 16px;
    padding-bottom: 8px;
    height: 52px;
    p {
      font-size: 0.625rem;
    }
    .player-column {
      p {
        padding-left: 4px;
      }
    }
  }
  .player-results-columns {
    position: sticky;
    top: 32px;
    padding-top: 16px;
    padding-bottom: 8px;
    height: 52px;
    display: flex;
    width: 100%;
    padding-right: 6px;

    p {
      font-size: 0.625rem;
    }
    .hole-info {
      width: auto;
      flex: 1;
      margin-right: 2px;
      min-width: unset;
      max-width: 22px;
      .hole-par {
        font-size: 0.625rem;
        margin-top: 6px;
      }
    }
  }

  .results {
    justify-content: flex-start;
    width: 100%;
    height: 28px;
    .result {
      font-size: 0.75rem;
      margin-right: 2px;
      height: 22px;
      flex: 1;
      width: auto;
      max-width: 22px;
    }
  }

  .player-info {
    width: 45%;

    &.small {
      width: 50%;
      .player-info-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.medium {
      width: 45%;

      .player-info-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.large {
      width: 38%;

      .player-info-columns {
        .player-column {
          flex: 4;
        }
      }
      .players {
        .name {
          flex: 4;
        }
      }
    }
  }
  .player-results {
    width: 55%;
    &.small {
      width: 50%;
      .player-results-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.medium {
      width: 55%;

      .player-results-columns {
        position: sticky;
        top: 32px;
      }
    }
    &.large {
      width: 62%;

      .result-table-wrapper {
        overflow-x: auto;
        overflow-y: hidden;
      }
    }
    .player {
      padding-left: 12px;
      padding-right: 6px;
    }
  }
  .player-stats-info-portrait {
    display: none;
  }
  .player-stats-info {
    width: 100%;
    display: flex;

    .left {
      flex: 3.5;
      display: flex;
      align-items: flex-end;
      padding-bottom: 4px;

      .watch-player {
        background: $mist;
        cursor: pointer;
        height: 28px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        border-radius: 6px;
        transition: background ease 0.3s;
        margin-left: 12px;
        white-space: nowrap;

        &:hover {
          background: $fog;
        }
        &.unwatch {
          background: $sunrise;
        }
      }
    }

    .stat-titles {
      flex: 3;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      > div {
        display: flex;
        align-items: center;
      }
    }

    .total-percentage {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      > div {
        display: flex;
        align-items: center;
      }
    }
    .round-percentage {
      flex: 1;
      display: flex;
      align-items: center;
      flex-direction: column;
      > div {
        display: flex;
        align-items: center;
      }
    }
  }

  @media (min-width: 1200px) {
    .live-controls {
      justify-content: flex-end;

      .control-spacer {
        width: 1px;
        height: 28px;
        background: $blizzard;
        margin: 0 24px;
      }
      .auto-refresh {
        align-items: center;
        .super-small {
          font-size: 0.75rem;
        }
      }
    }

    .division-container {
      margin-bottom: 48px;
    }
    .player-stats {
      margin-bottom: 42px;
      > div {
        height: 36px;
      }
      .stat {
        height: 32px;
        min-width: 32px;
        margin-right: 4px;
        span {
          width: 8px;
          height: 8px;
          border-radius: 4px;
        }
      }
    }
    .player-stats-info {
      margin-bottom: 42px;
      .left {
        .watch-player {
          height: 32px;
          padding: 0 12px;
          p {
            font-size: 0.875rem;
          }
        }
      }
      .stat-titles {
        > div {
          height: 36px;
        }

        p {
          font-size: 1rem;
        }
      }
      .total-percentage {
        > div {
          height: 36px;
        }
        p {
          font-size: 1rem;
        }
      }
      .round-percentage {
        > div {
          height: 36px;
        }
        p {
          font-size: 1rem;
        }
      }
    }
    .player {
      min-height: 36px;

      p {
        font-size: 1rem;
      }

      .position,
      .name,
      .thru,
      .total,
      .round {
        height: 36px;
      }
    }
    .results {
      height: 36px;
      .result {
        height: 32px;
        min-width: 32px;
        margin-right: 4px;
        p {
          font-size: 1rem;
        }
      }
    }
    .banner-left {
      height: 40px;
      border-radius: 6px 0 0 6px;
    }
    .banner-right {
      height: 40px;
      border-radius: 0 6px 6px 0;
    }
    .par-throw-toggle {
      display: flex;
      justify-content: space-between;
      position: relative;
      width: 120px;
      height: 28px;
      background: $blizzard;
      border-radius: 6px;
      cursor: pointer;
      user-select: none;

      .toggle-indicator {
        transition: all ease 0.3s;
        position: absolute;
        border-radius: 5px;
        height: 24px;
        width: 56px;
        top: 2px;
        left: calc(100% - 58px);
        background: white;
        z-index: 1;

        &.left {
          right: unset;
          left: 2px;
        }
      }

      .par-toggle,
      .throw-toggle {
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 56px;
        font-size: 12px;
        @include Gilroy-Bold;
        color: white;
        transition: all ease 0.3s;
        cursor: pointer;

        &.active {
          cursor: default;
          color: $midnight;
        }
      }
    }

    .player-results {
      &.small {
        .player-results-columns {
          top: 40px;
        }
      }
      &.medium {
        .player-results-columns {
          top: 40px;
        }
      }
      &.large {
        .results {
          .result {
            min-width: unset;
            width: 32px;
            max-width: unset;
            p {
              font-size: 0.875rem;
            }
          }
        }
        .result-table-wrapper {
          overflow: hidden;
        }
        .player-results-columns {
          top: 40px;
          .hole-info {
            min-width: unset;
            max-width: unset;
          }
        }
        .player-stats {
          .stat {
            min-width: unset;
            max-width: unset;
          }
        }
      }
    }

    .player-results-columns {
      padding-top: 24px;
      height: 78px;

      p {
        font-size: 0.875rem;
      }
      .hole-info {
        min-width: 32px;
        margin-right: 4px;
        .hole-par {
          margin-top: 10px;
          font-size: 0.75rem;
        }
      }
    }
    .player-info {
      &.small {
        .player-info-columns {
          top: 40px;
        }
      }
      &.medium {
        .player-info-columns {
          top: 40px;
        }
      }

      &.large {
        .player-info-columns {
          top: 40px;
        }
      }
    }
    .player-info-columns {
      padding-top: 24px;
      height: 78px;
      top: 40px;

      p {
        font-size: 0.875rem;
      }

      .round-column,
      .total-column {
        .pool-par {
          margin-top: 10px;
          font-size: 0.75rem;
        }
      }

      .player-column {
        min-height: 40px;
      }
      .pos-column {
        min-height: 40px;
      }
    }
  }
}
</style>